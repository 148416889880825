.min-vh-80 {
  min-height: 80vh;
}

@media (min-width:992px) {
  .dropdown-menu-custom {
    right: 0;
    left: auto;
    border-bottom: 1px solid #e9ecf0;
    border-left: 1px solid #e9ecf0;
    border-right: 1px solid #e9ecf0;
  }
}

@media (max-width:992px) {
  .dropdown-menu-custom {
    left: 0;
    right: auto;
    border-bottom: 1px solid #e9ecf0;
    border-left: 1px solid #e9ecf0;
    border-right: 1px solid #e9ecf0;
  }
}

.info-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 85%;
  color: #926dde;
}