
//-----------------------------------------------------------------
// Input group
//

.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  @extend .btn-lg;
}

.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  @extend .btn-sm;
}


//----------------------------------------------------/
// Validation
//----------------------------------------------------/
.input-group {

  .was-validated &:valid,
  &.is-valid {
    border-color: $color-success;

    .valid-feedback,
    .valid-tooltip,
    ~ .valid-feedback,
    ~ .valid-tooltip {
      display: block;
    }
  }

  .was-validated &:invalid,
  &.is-invalid {
    border-color: $color-danger;

    .invalid-feedback,
    .invalid-tooltip,
    ~ .invalid-feedback,
    ~ .invalid-tooltip {
      display: block;
    }
  }

}
